export function action(type, config) {
  if (typeof config === "function") {
    return defineType(type, (...args) => Object.assign(Object.assign({}, config(...args)), {
      type
    }));
  }
  const as = config ? config._as : "empty";
  switch (as) {
    case "empty":
      return defineType(type, () => ({
        type
      }));
    case "fsa":
      return defineType(type, (payload, meta) => payload instanceof Error ? {
        error: true,
        meta,
        payload,
        type
      } : {
        error: false,
        meta,
        payload,
        type
      });
    case "payload":
      return defineType(type, payload => ({
        payload,
        type
      }));
    case "props":
      return defineType(type, props => Object.assign(Object.assign({}, props), {
        type
      }));
    default:
      throw new Error("Unexpected config.");
  }
}
export function empty() {
  return {
    _as: "empty"
  };
}
export function fsa() {
  return {
    _as: "fsa",
    _m: undefined,
    _p: undefined
  };
}
export function payload() {
  return {
    _as: "payload",
    _p: undefined
  };
}
export function props() {
  return {
    _as: "props",
    _p: undefined
  };
}
export function type(type, rest) {
  return Object.assign(Object.assign({}, rest), {
    type
  });
}
export function union(...creators) {
  const descriptor = {
    get: () => {
      throw new Error("Pseudo property not readable.");
    },
    set: () => {
      throw new Error("Pseudo property not writable.");
    }
  };
  const result = creators.map(({
    type
  }) => Object.defineProperty({
    type
  }, "action", descriptor));
  Object.defineProperties(result, {
    actions: descriptor,
    types: descriptor
  });
  return result;
}
function defineType(type, creator) {
  return Object.defineProperty(creator, "type", {
    value: type,
    writable: false
  });
}